<template>
    <div class="page-rooter-fff">
        <headBar ref="headerHeight" title="邀请有礼" left-arrow @click-left="newAppBack" />

        <div class="page-h">
            <div class="user-info">
                <div class="user-head" :class="{ bg: headImg ? false : true }">
                    <img :src="headImg" alt="" v-if="headImg">
                </div>
                <p class="bold">{{ nickName }}</p>
                <div class="bold">{{ rewardName }}</div>
                <!--                <span v-if="expireTime">有效期：{{ expireTime }}</span>-->
            </div>
            <div class="left-btn row-center-center">
                <img :src="rewardImg" alt="">
            </div>
            <div class="gift-nav col-start-center">
                <template v-if="[1, 3].includes(status)">
                    <p>已获得48元团课券<br>快去体验团课吧~ </p>
                    <div @click="jumpApp(1)" class="row-center-center">去预约团课</div>
                </template>
                <!-- <template v-else-if="[6, 8].includes(status)">
                    <p>接受邀请后14天内去体验团课<br>你的朋友也能同样获得奖品哦</p>
                    <div @click="jumpApp(1)" class="row-center-center">已领取</div>
                </template> -->
                <template v-else>
                    <p>接受邀请后，14天内即可体验团课，<br>开启冬日第一节燃脂操！</p>
                    <div @click="acceptInvite" class="row-center-center">接受邀请</div>
                </template>
            </div>
        </div>
        <div class="rights-package" v-if="voucherState === 1">
            <div class="sub" @click="jump('superdeer-activity/new-people')"></div>
        </div>
        <div class="rights-package-use" v-if="voucherState === 2">
            <div class="voucher">
                <div class="v-left">
                    <span class="BebasNeueBold value">48</span>
                    <span>元</span>
                </div>
                <div class="v-right">
                    <div class="voucher-name ellipsis-2">0.01元体验48元精品团课（新人专属）</div>
                    <div class="voucher-time">有效期：{{ voucherObj.time || '--' }}</div>
                </div>
            </div>
            <div class="sub" @click="jumpApp(1)"></div>
        </div>
        <div class="course-content">
            <img src="https://img.chaolu.com.cn/ACT/group-invite-202304/accept_2.png" alt="">
        </div>

        <div class="course-content newbox">
            <img src="https://img.chaolu.com.cn/ACT/group-invite-202412/newhead.png" alt="" class="mhead" />
            <template v-for="item, index in courseJson">
                <div class="line" v-if="index > 0"></div>
                <div class="new-model">
                    <img :src="'https://img.chaolu.com.cn/ACT/group-invite-202412/' + item.key + '.png'" alt="" />
                    <div class="new-sub" @click="toDetail(item.json)">去约课</div>
                </div>

            </template>
        </div>
        <van-popup v-model="showSky" position="center" class="pop-get">
            <div class="pop-get-body">
                <div class="voucher">
                    <div class="v-left">
                        <span class="BebasNeueBold value">0.01</span>
                        <span>元</span>
                    </div>
                    <div class="v-right">
                        <div class="voucher-name ellipsis-2">0.01元体验48元精品团课（新人专属）</div>
                        <div class="voucher-time">有效期：{{ voucherObj.time || '--' }}</div>
                    </div>
                </div>
                <div class="pop-get-sub" @click="closeSky"></div>
            </div>
            <img class="popclose" src="https://img.chaolu.com.cn/ACT/micro-invite-202305/popclose.png"
                @click="closeSky" />
        </van-popup>
    </div>
</template>

<script>
import {
    newAppBack,
    initBack,
    goCourseDetail,
    defaultApp,
    webAppShare,
    gotoAppPage,
    gotoCoachDetail
} from '@/lib/appMethod';
import headBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import wx from "weixin-js-sdk";
export default {
    components: {
        headBar
    },
    mixins: [userMixin],
    name: "accept",
    data() {
        return {
            inviteUid: '',
            bindTeacherId: '',
            classId: '',
            status: '',
            expireTime: '',
            content: '',
            nickName: '',
            headImg: '',
            userName: '',
            rewardImg: '',
            rewardName: '',
            teacherInfo: {
                coachLabels: [],
                photos: [],
            },
            classInfo: {},
            showSky: false,
            voucherObj: {},
            voucherState: undefined,//0-显示领取券，1-显示权益，2-去使用， 3-防薅拦截隐藏
            courseJson: [
                {
                    key: 'l_sxgl',
                    json: {
                        3: '7647b1de24d8419f94077dd8dae77c73',
                        '036652995ac5451fbb6791c3491e1c2d': '1246059406341177344',
                        '818447834079563776': '64034541169213637062215724086590'
                    }
                },
                {
                    key: 'l_rzbj',
                    json: {
                        3: '769b681b2af0482a89fcae737252847a',
                        '036652995ac5451fbb6791c3491e1c2d': '1246058972679503872',
                        '818447834079563776': '38405460182954991985018379041011'
                    }
                },
                {
                    key: 'l_mbwl',
                    json: {
                        3: 'b56f15ce0a024ebd99582d00a62a074d',
                        '036652995ac5451fbb6791c3491e1c2d': '1237821744295706624',
                        '818447834079563776': '53173280668606327683341353836788'
                    }
                },
                {
                    key: 'l_yfds',
                    json: {
                        3: '21843f3657974527b1d745f86cd78cf5',
                        '036652995ac5451fbb6791c3491e1c2d': '1237815169451556864',
                        '818447834079563776': '86763232785056564652371068161326'
                    }
                },
                {
                    key: 'l_yj',
                    json: {
                        3: 'a09880be6b654c82b53d8032db13457e',
                        '036652995ac5451fbb6791c3491e1c2d': '1246059269262934016',
                        '818447834079563776': '60368311051622321199450068361545'
                    }
                },
                {
                    key: 'l_rzqx',
                    json: {
                        3: '5d96110de0604a4b97be0cecb659287e',
                        '036652995ac5451fbb6791c3491e1c2d': '1233109481303310336',
                        '818447834079563776': '99682114896999784810656721527368'
                    }
                },
                {
                    key: 'l_clzs',
                    json: {
                        3: '5b68b901cf1a47059bd484337bdd7327',
                        '036652995ac5451fbb6791c3491e1c2d': '1246057879379640320',
                        '818447834079563776': '58052634865094575676800334525109'
                    }
                }
            ]
        }
    },
    async created() {
        initBack();
        // if (this.appTypeStr !== 'mini' && this.locationUrl == 'https://web.chaolu.com.cn') {
        //     this.$dialog.alert({
        //         title: '提示',
        //         message: '请使用微信扫码',
        //         overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
        //     }).then(() => {
        //         this.newAppBack();
        //     })
        //     return;
        // }
        this.inviteUid = this.$route.query.inviteUid || ''
        this.classId = this.$route.query.classId || ''
        this.bindTeacherId = this.$route.query.teacherId || ''
        await this.$getAllInfo(['userId', 'cityId']);
        this.acceptInviteCheck()
        if (this.classId) {
            this.getClassInfo()
        }
        if (this.bindTeacherId) {
            // this.getTeacher()
        }
    },
    methods: {
        newAppBack,
        toDetail(json) {
            const id = json[this.cityId]; console.log(id);
            goCourseDetail(id)

            // App.intentToActivity(JSON.stringify({
            //     "className": ".view.group.GroupCourseDetailActivity", "data": [
            //         {
            //             "key": "EXTRA_COURSEID",
            //             "value": '1238185957711151104'
            //         }]
            // })
            // )
        },
        acceptInviteCheck() {
            this.$axios.post(`${this.baseURLApp}/schedule/invitation/acceptInviteCheck`, {
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                referrerId: this.inviteUid,
                isNewUser: 0
            }).then(r => {
                this.status = r.data.status

                // if(process.env.NODE_ENV === "development"){
                //     this.status = 0
                // }


                this.content = r.data.content
                this.userName = r.data.userName
                this.expireTime = r.data.expireTime
                this.rewardName = r.data.rewardName
                this.rewardImg = r.data.rewardImg
                this.nickName = r.data.inviterUserName
                this.headImg = r.data.inviterUserHeadPortrait || 'https://chaolucoachwebstatic.chaolu.com.cn/static/img/WeChat/coach/logo.jpg'

                if (this.status === 2 || this.status === 5) {
                    this.$dialog.alert({
                        title: '接受邀请失败',
                        message: this.content,
                        confirmButtonText: this.status === 2 ? '立即参加' : '我知道了',
                        confirmButtonColor: '',
                        overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
                    }).then(() => {
                        this.jump()
                    });
                }
                if (this.status === 10 || this.status === 3) {//10-用户已绑定-与邀请人一致
                    this.$dialog.alert({
                        title: '温馨提示',
                        message: `您已绑定邀请人${this.userName}，领取后不会更改绑定关系`,
                        confirmButtonText: '我知道了',
                        confirmButtonColor: '',
                    }).then(() => {
                        this.voucherInfo();
                    });
                }
                if (this.status === 6) {
                    this.$dialog.alert({
                        title: '温馨提示',
                        message: this.content,
                        confirmButtonText: '我知道了',
                        confirmButtonColor: '',
                    }).then(() => {
                        this.jump()
                    });
                }
                if (this.status === 4) {
                    this.$dialog.alert({
                        title: '温馨提示',
                        message: `亲爱的续转会员，为了让您享受更优质的超鹿服务，我们为您独家升级新人邀请礼包。\n1、续转会员有效期内，邀请1位好友，最高可延长13天年卡权益；\n2、原有获得且未领取的128礼包仍可领取。\n 由此给您带来的不变，我们深感歉意。如有疑问，可拨打客服电话：\n 400-700-5678`,
                        confirmButtonText: '查看新的邀请奖励',
                        overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
                    }).then(() => {
                        defaultApp();
                        if (this.appTypeStr === 'mini') {
                            this.$router.push("/emptyPage?isUndo=1")
                        } else {
                            window.location.href = `${this.baseURL}/index/identityInvite?userId=${this.userId}`
                        }

                    });
                    return false;
                }
                if (this.status === 7) {
                    this.$dialog.alert({
                        title: '接受邀请失败',
                        message: this.content,
                        confirmButtonText: '我知道了',
                        overlayStyle: { backgroundColor: 'rgba(0,0,0,1)' }
                    }).then(() => {
                        this.jump()
                    });
                    return false;
                }
            })
        },
        closeSky() {
            this.voucherState = 2;
            this.showSky = false;
        },
        acceptInvite() {
            this.$toast.loading({
                duration: 0,
                forbidClick: true,
                loadingType: 'spinner',
            })
            this.$axios.post(`${this.baseURLApp}/schedule/invitation/acceptInvite`, {
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                referrerId: this.inviteUid,
                isNewUser: 0
            }).then(r => {
                this.$toast.clear();
                this.status = r.data.status
                if (this.status === 1) {
                    this.status = 1;
                    this.voucherInfo();
                }
            })
        },
        voucherInfo() {
            this.$axios.post(`${this.baseURLApp}/schedule/invitation/claimCoupons`, {
                hasWx: true,
                userId: this.userId
            }).then((res) => {
                this.voucherObj = res.data;
                this.voucherState = res.data.status;
                if (this.voucherState === 0) {
                    this.showSky = true;
                }
            })
        },
        getTeacher() {
            this.$axios.post(`${this.baseURLApp}/app/teacher/oldBringNew/getTeacherInfo`, {
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                teacherId: this.bindTeacherId,
            }).then(r => {
                this.teacherInfo = r.data
            })
        },
        getClassInfo() {
            this.$axios.post(`${this.baseURLApp}/micro/invite/get/clazz`, {
                "hasWx": true,
                "token": this.token,
                "userId": this.userId,
                id: this.classId,
            }).then(r => {
                this.classInfo = r.data
            })
        },
        jump(path = 'superdeer-activity/group-invite-202412/index', parma = '') {
            // superdeer-activity/invite-2023  邀请页
            if (this.appTypeStr === 'mini') {
                wx.miniProgram.redirectTo({
                    url: `/pages/webView/index?webUrl=${window.location.origin}/#/${path}&userId=1&token=1&location=1${parma}`,
                })
            } else {
                this.$router.replace({ path: `/${path}?userId=1&token=1&location=1&cityId=1${parma}` })
            }
        },
        jumpApp(type) {
            if (type === 1) {
                gotoAppPage(3)
            } else {
                gotoCoachDetail(this.bindTeacherId)
            }
        },
    }
}
</script>

<style scoped lang="less">
.page-rooter-fff {
    min-height: 100vh;
    background: #FFE9E0 linear-gradient(180deg, #FAE5CF 0%, #FFE8C5 100%);
    padding-bottom: 90px;
    box-sizing: border-box;
}

.page-h {
    width: 678px;
    height: 706px;
    background: url("https://img.chaolu.com.cn/ACT/group-invite-202304/accept_5.png") center center no-repeat;
    background-size: 100% 100%;
    position: relative;
    margin: 82px auto 0;

    .user-info {
        //padding-top: 30px;
        position: relative;
        top: -48px;
        text-align: center;
        .tips-icon {
            position: absolute;
            top: 282px;
            right: 174px;
            width: 114px;
            height: 50px;
            background: linear-gradient(90deg, #FF7A00 0%, #FF4C24 100%);
            border-radius: 24px 32px 32px 4px;
            line-height: 26px;
            font-weight: bold;
            font-size: 26px;
            color: #FFFFFF;
            margin-top: 0;
        }

        .user-head {
            margin: 0 auto;
            width: 104px;
            height: 104px;
            border: 4px solid #FFF6F0;
            border-radius: 50%;
            box-sizing: border-box;
            overflow: hidden;
        }

        .bg {
            background: #f5f5f5;
        }

        img {
            width: 100%;
            height: 100%;
        }

        p {
            margin-top: 20px;
            line-height: 26px;
            font-size: 26px;
            color: #242831;
            height: 26px;
        }

        div {
            margin-top: 24px;
            font-size: 32px;
            color: #761624;
            line-height: 32px;
            height: 32px;
        }

        span {
            margin-top: 204px;
            color: #761624;
            line-height: 24px;
            font-size: 24px;
            text-align: center;
        }
    }

    .left-btn {
        position: relative;
        top: -16px;

        img {
            width: auto;
            height: 204px;
            display: block;
        }
    }

    .gift-nav {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 678px;
        height: 244px;

        div {
            width: 480px;
            height: 104px;
            font-family: PuHuiTi;
            color: #B24229;
            font-size: 44px;
            background: url("https://img.chaolu.com.cn/ACT/group-invite-202304/accept_4.png") no-repeat center center;
            background-size: 100% 100%;
        }

        p {
            color: #FFFFF3;

            font-weight: bold;
            line-height: 40px;
            font-size: 26px;
            //margin-top: 36px;
            margin-bottom: 24px;
            text-align: center;
        }
    }
}

.rights-package {
    width: 702px;
    height: 555px;
    margin: 32px auto 0;
    background: url(https://img.chaolu.com.cn/ACT/group-invite-202304/rewardbg-get1.png);
    background-size: 100% 100%;
    position: relative;
}

.rights-package-use {
    box-sizing: border-box;
    width: 702px;
    height: 463px;
    margin: 32px auto 0;
    background: url(https://img.chaolu.com.cn/ACT/group-invite-202304/rewardbg-use1.png);
    background-size: 100% 100%;
    position: relative;
    padding-top: 181px;
}

.voucher {
    height: 140px;
    display: flex;
    justify-content: center;
    color: #B54228;

    .v-left {
        width: 190px;
        margin-right: 2px;
        background: linear-gradient(0deg, #FFE8CE 0%, #FFF0D7 100%);
        border-radius: 14px;
        font-size: 28px;
        font-weight: bold;
        padding-top: 40px;
        box-sizing: border-box;
        text-align: center;

        .value {
            font-size: 66px;
            font-weight: normal;
            margin-right: 11px;
            line-height: 1;
        }
    }

    .v-right {
        width: 390px;
        background: linear-gradient(0deg, #FFE8CE 0%, #FFF0D7 100%);
        border-radius: 14px;
        padding: 0 22px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .voucher-name {
            font-size: 28px;
            font-weight: bold;
            line-height: 1.2;
        }

        .voucher-time {
            font-size: 22px;
            margin-top: 15px;
            line-height: 1;
        }
    }
}

.sub {
    position: absolute;
    left: 50%;
    bottom: 32px;
    transform: translateX(-50%);
    height: 78px;
    width: 320px;
}

.share-user-medal {
    display: flex;
    padding: 0 28px 20px;
    box-sizing: border-box;
    background: #FFFCF7;

    img {
        width: 152px;
        height: 152px;
        border-radius: 12px;
        margin-right: 24px;
        flex-shrink: 0;
    }

    .use-header-name-medal {
        //margin-left: 16px;
        font-size: 28px;
        font-weight: bold;
        color: #AA3E1E;
        width: 470px;
    }

    .poster-code-cont-medal {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        box-sizing: border-box;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 50%;
        }
    }

    .use-header-label {
        margin-top: 16px;
        flex-wrap: wrap;
        font-size: 20px;
        border-radius: 6px;
        background: #FFFAF8;
        padding: 0;
        // border: 2px solid #FAE4D5;
        line-height: 30px;
        box-sizing: border-box;

        span {
            color: #761624;
        }
    }

    .course-box {
        h4 {
            line-height: 28px;
            font-size: 28px;
            font-weight: bold;
            color: #AA3E1E;
            padding-top: 8px;
        }

        &>div {
            margin-top: 24px;
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            color: #3C454E;

            .coach-name {
                //transform: translateY(2px);
            }

            .level {
                margin-left: 10px;
                font-size: 20px;
                line-height: 20px;
                font-weight: bold;
                background: #3C454E;
                color: white;
                padding: 4px 6px;
                border-radius: 2px;
                transform: translateY(-2px);
            }
        }

        p {
            margin-top: 24px;
            font-size: 24px;
            font-weight: 400;
            line-height: 24px;
            color: #3C454E;

            span {
                margin-left: 24px;
            }

        }
    }
}

.course-content {
    width: 702px;
    margin: 32px auto 0;

    //border-radius: 20px;
    img {
        width: 100%;
        display: block;
    }
}

.newbox {
    background: #FFFCF7;
    border-radius: 20px;
    padding-bottom: 48px;
    margin-top: 70px;

    .mhead {
        position: relative;
        top: -40px
    }

    .new-model {
        position: relative;

        .new-sub {
            position: absolute;
            bottom: 0;
            right: 28px;
            width: 160px;
            height: 64px;
            background: linear-gradient(90deg, #FF7F37 0%, #FF3672 100%);
            border-radius: 6px;
            text-align: center;
            line-height: 64px;
            font-weight: 600;
            font-size: 26px;
            color: #FFFFFF;

        }
    }

    .line {
        margin: 48px 28px;
        height: 1px;
        background: #FFC6BA;
    }
}

.course-bottom-btn {
    padding: 0 32px 32px;
    background-color: #FFFCF7;
    border-radius: 0 0 20px 20px;

    div {
        width: 128px;
        height: 56px;
        background: linear-gradient(90deg, #FF8639 0%, #FD5239 100%);
        border-radius: 6px;
        color: #FFFFFF;
        font-size: 24px;
    }
}

.bold {
    font-weight: bold;
}

.price {
    margin-right: 24px;
}

.price .t1 {
    color: #F03C18;
    line-height: 24px;
    font-size: 24px;
    font-family: BebasNeueBold;
    //position: relative;
    //top: 4px;
}

.price .t2 {
    color: #F03C18;
    line-height: 28px;
    font-size: 40px;
    font-family: BebasNeueBold;
    margin-right: 4px;
    //position: relative;
    //top: 4px;
}

.price .t3 {
    color: #3C454E;
    line-height: 22px;
    font-size: 22px;
}

.price .t4 {
    color: #3C454E;
    line-height: 24px;
    font-size: 24px;
    margin-left: 24px;
}

.coach-pic {
    background-color: #FFFCF7;
    //border-radius: 0 0 20px 20px;
    padding: 0 28px 24px;

    img {
        width: 204px;
        height: 204px;
        border-radius: 12px;
        margin-left: 16px;
        object-fit: cover;

        &:first-child {
            margin-left: 0;
        }
    }
}

.coach-labels {
    margin-top: 16px;
    flex-wrap: wrap;

    span {
        height: 36px;
        margin-bottom: 8px;
        padding: 0 12px;
        border-radius: 6px;
        border: 1px solid #FFDAC0;
        font-size: 20px;
        font-weight: bold;
        line-height: 38px;
        color: #D35005;
        margin-right: 10px;
    }
}

.pop-get {
    background: transparent;

    .pop-get-body {
        width: 660px;
        height: 600px;
        background: url(https://img.chaolu.com.cn/ACT/group-invite-202304/rewardbg-sky1.png);
        background-size: 100% 100%;
        padding-top: 269px;
        position: relative;
        box-sizing: border-box;


        .pop-get-sub {
            position: absolute;
            left: 50%;
            bottom: 50px;
            transform: translateX(-50%);
            height: 94px;
            width: 400px;
        }
    }

    .popclose {
        width: 60px;
        height: 60px;
        display: block;
        margin: 64px auto 0;
    }
}
</style>
